/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";

.router-wrapper  {
    position:  relative;
    overflow:  hidden;
    width:  100vw;
    height:  calc(100%  -  47px);
    perspective:  1200px;
    transform-style:  preserve-3d;
}

:host  {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}

.pl-0{
    padding-left: 0;
}

.mt-32{
    margin-top: 32px;
}

.username{

    span{
        font-weight: bold;
        font-size: 24px;
        line-height: 14px;
        vertical-align: middle;
    }
}